import React from 'react';
import { useState } from 'react';
import './ContactPage.scss'
import Swal from 'sweetalert2'

const ContactPage = () => {
    const [formData, setFormData] = useState({
        fullname: '',
        email: '',
        phone: '',
        address: '',
        message: ''
    });

    const handleFormChange = (e) => {
        e.preventDefault();
        let value = e.target.value;
        let target = e.target.name;

        setFormData( prev => ({...prev, [target]: value}))
    }

    const handleSendEmail = () => {
        if (formData.fullname.length && formData.email.length && formData.phone.length && formData.message.length) {
            // send email here
            setFormData({fullname:'', email:'', phone: '', address: '', message: ''})
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Email was sent successfully!',
                showConfirmButton: false,
                timer: 1500
              })
        } else {
            return;
        }
    }

    const getRows = () => {
        let width = window.innerWidth;
        let rows = 20;
        if (width > 768 && width <= 1450) {
            rows = 18;
        } else if (width <= 768 && width > 438) {
            rows = 16;
        } else if (width <= 438) {
            rows = 5;
        }

        return rows;
    }

    return (
        <section id='section-contact'>
            <div id='contact-form-container'>
                <h4 style={{ textAlign: 'center'}}>Please call to &nbsp; 
                <a href='tel:0411446804'>
                    <button className='contact-btn hotline-btn' onClick={() => {}}>Mobile</button>
                </a>&nbsp;
                or send the message, we will get back to you as soon as possible</h4>
                <div className='contact-form'>
                    <div className='contact-details'>
                        <div className='input-group'>
                            <label htmlFor='fullname'>Name<span>*</span></label>
                            <input type={'text'} name='fullname' value={formData.fullname} onChange={handleFormChange} placeholder='Your name' required/>
                        </div>
                        <div className='input-group'>
                            <label htmlFor='email'>Email Address<span>*</span></label>
                            <input type={'email'} name='email' value={formData.email} onChange={handleFormChange} placeholder='Email adrress' required/>
                        </div>
                        <div className='input-group'>
                            <label htmlFor='phone'>Phone Number<span>*</span></label>
                            <input type={'number'} name='phone' value={formData.phone} onChange={handleFormChange} placeholder='Phone number' required/>
                        </div>
                        <div className='input-group'>
                            <label htmlFor='address'>Residential Address</label>
                            <input type={'text'} name='address' value={formData.address} onChange={handleFormChange} placeholder='Your address' required/>
                        </div>
                    </div>
                    <div className='contact-message'>
                        <textarea rows={getRows()} id='contact-textarea' name='message' value={formData.message} onChange={handleFormChange} placeholder="Your message"/>
                    </div>
                </div>
                <div className='form-action'>
                    <button className='contact-btn' onClick={handleSendEmail}>Send Message</button>
                </div>
            </div>
        </section>
    )
}

export default ContactPage;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneVolume, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { Routes, Route } from 'react-router';
import './App.scss';
import HomePage from './HomePage/HomePage';
import ContactPage from './ContactPage/ContactPage';
import { useEffect, useState } from 'react';

function App() {
  const [currentImg, setCurrentImg] = useState(0);

  useEffect( () => {
    setTimeout( () => {
     setCurrentImage()
    }, 3000)

    return () => clearTimeout(setCurrentImage())
  })

  const setCurrentImage = () => {
    if (currentImg === images.length - 1) {
      setCurrentImg(1);
    } else {
      setCurrentImg(currentImg + 1);
    }
  }

  const images = [
    '/images/medium/in_1.jpg',
    '/images/medium/in_2.jpg',
    '/images/medium/in_3.jpg',
    '/images/works/house-12.jpg',
    '/images/medium/in_6.jpg',
    '/images/medium/ex_1.jpg',
    '/images/medium/ex_2.jpg',
    '/images/medium/ex_4.jpg',
  ];

  
  return (
    <div className={'container'}>
      <section id='container-header'>
        <div className={'header'}>
          <div className={'header-items'}>
            <div id='logo-container'>
              <img src='/images/medium/logo-nobg.png' alt='company-logo' id='company-logo'/>
            </div>
              <h4 id='header-text'>Contact Us Today To Get Free Quote</h4>
              
              <div className='header-item-container'>
                <div className='header-item phone'>
                  <FontAwesomeIcon 
                    className={'header-icon'}
                    icon={faPhoneVolume} 
                    style={{color: 'orangered', fontSize: '1.3em', marginRight: '.5rem'}}
                  />
                  <span><a href='tel:0470715111' className='phone-number'>0470-715-111</a></span>
                </div>
                <div className='header-item email'>
                  <FontAwesomeIcon 
                    className={'header-icon'}
                    icon={faEnvelope} 
                    style={{color: 'orangered', fontSize: '1.3em', marginRight: '.5rem'}}
                  />
                  <span>info@hvpaintingservices.com.au</span>
                </div>
              </div>
          </div>
        </div>
        <div className={'menu'}>
          <div id='company-name-box'>
            <div className='name-box-container'>
              {/* <img src={'/images/logo.jpg'} alt='Business Logo' style={{width: '20rem'}}/> */}
              <h1 className='menu-company-name'>HV Painting Services</h1>
              <h2 className='description'>The professional Services that you can trust</h2>
              <a href='tel:0470715111'>
                <button className='contact-btn' onClick={() => {}}>Get a Quote</button>
              </a>
            </div>
          </div>
          <div id='carousel-box'>
            <img src={images[currentImg]} alt='painting service images' className='carousel-img'/>
          </div>
        </div>
      </section>

      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route exact path='/section-contact' element={<ContactPage />} />
      </Routes>

      <footer id='footer-container'>
        <div className='footer-content'>
          <div className='footer-item logo'>
            <img src='/images/medium/logo-nobg.png' alt='company-logo' style={{height: '3rem'}}/>
          </div>
          <div className='footer-item'>
            <h1 className='menu-company-name'>HV Painting Services</h1>
            <h5 className='copy-right'>&copy; 2022 All Rights Reserved</h5>
          </div>
          
          <div className='footer-item email'>
            <FontAwesomeIcon 
              className={'header-icon'}
              icon={faEnvelope} 
              style={{color: 'orangered', fontSize: '1.3em', marginRight: '.5rem'}}
            />
            <span>info@hvpaintingservices.com.au</span>
          </div>
          
          <div className='footer-item address'>
            <FontAwesomeIcon 
              className={'header-icon'}
              icon={faBuilding} 
              style={{color: 'orangered', fontSize: '1.3em', marginRight: '.5rem'}}
            />
            <span>Marangaroo, WA 6064</span>
          </div>
          
          <div className='footer-item'>
              <a href='tel:0470715111'>
                <button className='contact-btn' onClick={() => {}}>Call Now</button>
              </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCity, faList, faPaintRoller, faUserFriends, faChevronUp, faQuoteLeft,faQuoteRight, faStar } from '@fortawesome/free-solid-svg-icons';
import './HomePage.scss'
import ContactPage from '../ContactPage/ContactPage';
import Gallery from '../Gallery/Gallery';
import { autoUpdateNumber } from '../util/utils';

const HomePage = () => {
    const services = [
        {title: 'Residential Painting', img: '/images/medium/in_1.jpg'},
        {title: 'Commercial Painting', img: '/images/works/buiding-1.webp'},
        {title: 'Colour Design', img: '/images/medium/bg-2.jpg'},
        {title: 'Interior Painting', img: '/images/medium/in_2.jpg'},
        {title:'Exterior Painting', img: '/images/medium/ex_4.jpg'},
        {title:'Roof Restoration', img: '/images/works/roof-1.webp'},
    ];

    const goTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    const stars = [];
    
    for (let i = 0; i < 5; i++ ) {
        stars.push(
            <FontAwesomeIcon icon={faStar} key={i} className='review-star' />
        )
    }
    return (
        <div id='home-container'>
            { document.body.scrollTop > 20 || document.documentElement.scrollTop > 20 ? 
                         <div id={'scroll-top-btn'} onClick={goTop}>
                         <FontAwesomeIcon icon={faChevronUp} size='lg' />
                     </div>
         
             : ''}
            <section id='section-about'>
                <div className='section-sub'>
                    <h1 className={'section-title'}>About Us</h1>
                    <div className='about-container'> 
                        <div className='about-photos'>
                            <img src='/images/medium/in_1.jpg' alt='interior painting' className='about-photo-1'/>
                            <img src='/images/medium/in_4.jpg' alt='interior painting' className='about-photo-2'/>
                            <img src='/images/medium/ex_2.jpg' alt='interior painting' className='about-photo-3'/>
                        </div>
                        <div className='about-description'>
                            <p className='about-content' >
                                <img src='/images/medium/logo-nobg.png' alt='company-logo' id='about-company-logo'/>
                                <br/>
                                <strong>HV Painting Services</strong> is a Perth, Western Australia local business which offers you&nbsp;
                                <strong><em>unbeatable painting services with stress-free experience</em></strong> whether it's a residential painting work or a commercial painting project. 
                                Our high-skilled and experienced team of painters will deliver high quality services to meet your expectations.<br/><br/>
                                We have been providing painting services to thousands of buildings and houses across Perth suburbs from Mindarie to Mandurah with our highest responsibility.
                                We guarantee that you will be happy with our works.<br/><br/>
                                <strong>Let HV Painting Services take care of all the painting jobs for you.</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            
            <section id='section-services'>
                <h1 className={'section-title'}>Our Services</h1>
                <div className='services-container'>
                    {services.map( item => 
                        <div className='service-card' key={item.title}>
                            <img src={item.img} alt={item.title} className='service-card-img'/>
                            <div className='card-title-container'>
                                <h2 className='service-card-title'>{item.title}</h2>
                            </div>
                        </div>    
                    )}

                </div>
            </section>
            
            <section id='section-choose-us'>
                <div className='section-sub'>
                    <h1 className={'section-title'}>Why Choosing Us?</h1>
                    <div className='choose-us-container'> 
                        <div className='choose-us-description'>
                            <p className='choose-us-content'>
                                <FontAwesomeIcon icon={faPaintRoller} color='orange' className='choose-us-icon' /> We are experts with more than 10 years of experience.
                            </p>
                            <p className='choose-us-content' >
                                <FontAwesomeIcon icon={faPaintRoller} color='orange' className='choose-us-icon'/> We provide various painting services that meet your needs.
                            </p>
                            <p className='choose-us-content' >
                                <FontAwesomeIcon icon={faPaintRoller} color='orange' className='choose-us-icon'/> We look after every detail in your project.
                            </p>
                            <p className='choose-us-content'>
                                <FontAwesomeIcon icon={faPaintRoller} color='orange' className='choose-us-icon' /> We provide exceptional customer services.
                            </p>
                            <p className='choose-us-content'>
                                <FontAwesomeIcon icon={faPaintRoller} color='orange' className='choose-us-icon' /> We always use the high quality products for your projects.
                            </p>
                            <p className='choose-us-content'>
                                <FontAwesomeIcon icon={faPaintRoller} color='orange' className='choose-us-icon' /> We guarantee all the works will be completed on time, on budget at a highest quality. 
                            </p>
                        </div>
                        <div className='choose-us-photos'>
                            <img src='/images/bg-3.jpg' alt='interior painting' className='choose-us-photo'/>
                        </div>
                    </div>
                    <div>
                        <h1 className='choose-us-h1'>Best Services - High Responsibility - Better Price</h1>
                    </div>
                    <div className='choose-us-feature'>
                        <div className='feature-card card-1'>
                            <h1>{autoUpdateNumber(1028)}</h1>
                            <h4><FontAwesomeIcon icon={faList} /> Completed Projects</h4>
                        </div>
                        <div className='feature-card card-2'>
                            <h1 style={{fontSize: '2rem', paddingTop: '.5rem'}}>Mindarie - Mandurah</h1>
                            <h4><FontAwesomeIcon icon={faCity} /> All Suburbs</h4>
                        </div>
                        <div className='feature-card card-3'>
                            <h1>{autoUpdateNumber(510)}</h1>
                            <h4><FontAwesomeIcon icon={faUserFriends} /> Happy Customers</h4>
                        </div>
                    </div>
                </div>
            </section>

            <section id='testimonial'>
                <div className='testimonial-container'> 

                    <div className='testimonial-item'>
                        <blockquote className='testimonial-content'>
                    <FontAwesomeIcon icon={faQuoteLeft} className='quote-mark'/><br/><em> Ha provided a great service. He was friendly, helpful and was able to fit in with my very tight schedule. The job was completed on time and I’m thrilled. </em><br/><FontAwesomeIcon icon={faQuoteRight} className='quote-mark right' />
                        </blockquote>
                        <div  className='star-container'>
                            {stars}
                        </div>
                        <h3 className='customer-name'>Katherine B</h3>
                        <h5 className='customer-address'>from Padbury, WA</h5>
                        <a href='/' rel='noreferrer' id='review-source'>See more HV Painting Services reviews</a>
                    </div>

                    <div className='testimonial-item middle'>
                        <blockquote className='testimonial-content'>
                        <FontAwesomeIcon icon={faQuoteLeft} className='quote-mark' /> <br/><em>They were on time, polite, clean and tidy as they worked and did a great job.
                         A small area was missed (poorly lit room). HV returned within 2 hours (on a Sunday!) to correct. We will definitely use this company again.</em><br/> <FontAwesomeIcon  className='quote-mark right' icon={faQuoteRight} />
                        </blockquote>
                        <div className='star-container'>
                            {stars}
                        </div>
                        <h3 className='customer-name'>Leana O</h3>
                        <h5 className='customer-address'>from Mariginiup, WA</h5>
                        <a href='/' rel='noreferrer' id='review-source'>See more HV Painting Services reviews</a>
                    </div>

                    <div className='testimonial-item'>
                        <blockquote className='testimonial-content'>
                    <FontAwesomeIcon  className='quote-mark' icon={faQuoteLeft}/><br/><em> Ha, was very quick to respond and book in a time to fix our storm damage. The work was done to a very high standard for a reasonable price. 
                    I will definitely use Ha again in the future.</em> <br/><FontAwesomeIcon icon={faQuoteRight} className='quote-mark right'/>
                        </blockquote>
                        <div  className='star-container'>
                            {stars}
                        </div>
                        <h3 className='customer-name'>Simon A</h3>
                        <h5 className='customer-address'>from Bedford, WA</h5>
                        <a href='https://hipages.com.au/connect/hvpaintingservices' target='_blank' rel='noreferrer' id='review-source'>See more HV Painting Services reviews</a>
                    </div>
                </div>
            </section>
            
            <Gallery />

            <ContactPage />
        </div>
    )
}

export default HomePage;
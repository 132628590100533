export const autoUpdateNumber = (number) => {
    let newValue = number;
    const createdDate = new Date("10/01/2022"); // 1st October 2022
    const today = new Date();
    const increaseVal = 3;

    let daydiff = Math.floor((today - createdDate) / (1000*60*60*24));

    if (daydiff >= 7) {
        newValue += Math.floor(daydiff/7)*increaseVal;
    }

    return newValue;
}
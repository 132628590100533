import * as React from 'react';
import './Gallery.scss'


const Gallery = () => {

    const projectPhotos = [
        '/images/works/buiding-1.webp',
        '/images/works/buiding-2.webp',
        '/images/works/house-1.webp',
        '/images/works/house-2.webp',
        '/images/works/house-3.webp',
        '/images/works/house-4.webp',
        '/images/works/house-5.webp',
        '/images/works/house-6.webp',
        '/images/works/house-7.webp',
        '/images/works/house-8.webp',
        '/images/works/house-9.webp',
        '/images/works/house-10.webp',
        '/images/works/house-11.jpg',
        '/images/works/house-12.jpg',
        '/images/works/house-13.jpg',
        '/images/works/fence-1.jpg',
        '/images/works/roof-1.webp',
        '/images/works/roof-2.webp',
        '/images/works/roof-3.webp',
        '/images/works/roof-4.webp',
    ];


    return (
        <section id='projects'>
            <div id='hinh-anh-container'>
                <h1 className={'section-title'}>Gallery</h1>
                
                <div id='project-photos'>
                    {
                        projectPhotos.map( (photo,key) => 
                        <img key={key} src={photo} alt='Project Done' className='project-photo'/>
                        )
                    }
                </div>
            </div>
        </section>
    )
}


export default Gallery;